import $ from 'jquery';

import easing from '../../node_modules/jquery-easing/dist/jquery.easing.1.3.umd.min.js'

import slick from '../../node_modules/slick-carousel/slick/slick.min.js'

import './_fouc.js'
import './_slider.js'
import './_menu.js'
import './_scrollDown.js'
import './_setEmailLink.js'
import './_equalHeight.js'
import './_equalGridElementHeight.js'
import './_calculateBoxHeight.js'
import './_highlightMenu.js'
import './_audioClips.js'
// import './_magnifier.js'
import './_zoomImage.js'
import './_transcript.js'
import './_actions.js'

// import $ from "jquery";
import { Interchange, Reflow } from "foundation-sites";

$(document).foundation();

import '../scss/styles.scss'

if (module.hot) {
  module.hot.accept(function () {
    window.location.reload();
  });
}