document.addEventListener('DOMContentLoaded', function() {
    const mappings = {
      gruen: {
        fill: 'rgb(190,209,141)',
        url: 'https://gcsmus.org/action-4-sci-pol-interface/',
      },
      gelb: {
        fill: 'rgb(250,224,185)',
        url: 'https://gcsmus.org/action-2-campuses/',
      },
      blau: {
        fill: 'rgb(154,208,228)',
        url: 'https://gcsmus.org/action-1-conference/',
      },
      violett: {
        fill: 'rgb(220,198,224)',
        url: 'https://gcsmus.org/action-3-post-docs/',
      },
      rot: {
        fill: 'rgb(241,178,177)',
        url: 'https://gcsmus.org/action-5-sci-comm-hub/',
      },
    //   titel: {
    //     fill: '#8685a5',
    //     url: 'https://www.youtube.com/watch?v=9QmGIqtAphE',
    //   },
    }

    for (let id in mappings) {
        let element = document.getElementById(id);
        if (element) {
            element.addEventListener('mouseover', function() {
                if (element) {
                    element.dataset.originalFill = element.style.fill;
                    element.style.fill = mappings[id].fill;
                }
                element.style.cursor = 'pointer';
            });

            element.addEventListener('mouseout', function() {
                if (element) {
                    element.style.fill = element.dataset.originalFill;
                }
                element.style.cursor = 'default';
            });

            element.addEventListener('click', function() {
                if (id === 'titel') {
                    window.open(mappings[id].url, '_blank');
                } else {
                    window.location.href = mappings[id].url;
                }
            });
        }
    }
});
