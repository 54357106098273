$(window).on('load', function () {
  let gridElement = $(".card")[0];
  
  function calculateSize() {
    $(".card .colored-box").each(function () {
      let width = $(this)[0].offsetWidth;
      $(".card .colored-box").height(width);
    });
  };

  calculateSize();  
  
  let resize;

  if (gridElement) {
    $(window).on('resize', function () {
        clearTimeout(resize);
        resize = setTimeout(function() {
          $(window).trigger('resize-window');
        }, 200);
    });

    $(window).on('resize-window', function() {
      calculateSize();
    });  
  }
});
