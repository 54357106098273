$(window).on('load', function () {
  let gridElement = $(".archive .card")[0];
  
  let shouldCalculate = false;
  if (window.innerWidth > 639) {
    shouldCalculate = true;
  }

  function calculateSize() {
    let maxHeightExcerpt = -1;
    let maxHeightTitle = -1;

    $(".archive .card .excerpt p").each(function () {
      maxHeightExcerpt =
        maxHeightExcerpt > $(this).outerHeight(true)
          ? maxHeightExcerpt
          : $(this).outerHeight(true);
      $(".archive .card .excerpt").height(maxHeightExcerpt);
    });

    $(".archive .card .title h2").each(function () {
      maxHeightTitle =
        maxHeightTitle > $(this).outerHeight(true)
          ? maxHeightTitle
          : $(this).outerHeight(true);
      $(".archive .card .title").height(maxHeightTitle+5);
    });
  };

  if(shouldCalculate) calculateSize();  
  
  let resize;

  if (gridElement && shouldCalculate) {
    $(window).on('resize', function () {
        clearTimeout(resize);
        resize = setTimeout(function() {
          $(window).trigger('resize-window');
        }, 200);
    });

    $(window).on('resize-window', function() {
      calculateSize();
    });  
  }
});
