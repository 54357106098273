$(document).ready(function () {
  const first_level = window.location.pathname.split('/')[1];
  const second_level = window.location.pathname.split('/')[2]; // country
  const third_level = window.location.pathname.split('/')[3];
  const taxonomy = window.location.pathname.split('/')[4];

   // Expand menu when site is visited without click the menu link
  if ($('.main-menu li').is(".current-page-parent") || $('.main-menu li').is(".current-menu-item") || $('.main-menu li').is(".current-menu-parent")) {

    if (first_level === "conferences") {
      if ($('.conferences').hasClass("current_page_ancestor")) {
        $('.conferences').addClass(second_level);
      }

      if ($('.main-menu li.current-menu-ancestor').hasClass(second_level)) {
        $(`.main-menu li.current-menu-ancestor.${second_level}`).addClass('is-active');
        $(`.main-menu li.current-menu-ancestor.${second_level}`).find('> .sub-menu, .current_page_item > .sub-menu').addClass('is-active');
      }
    } else {
      $('.main-menu li.current-menu-item').addClass('is-active');
      $('.main-menu li.current-menu-parent').addClass('is-active');
      
      $('.main-menu li.current-menu-ancestor').find('> .sub-menu, .current_page_item > .sub-menu').addClass("is-active");
      $('.main-menu li.current-menu-item').find('> .sub-menu, .current_page_item > .sub-menu').addClass("is-active");
      $('.main-menu li.current-menu-parent').find('> .sub-menu, .current_page_item > .sub-menu').addClass("is-active");
    }
    
    $('.current-page-ancestor').addClass('is-active');
    $('.current-page-ancestor').addClass("is-active");
    $('.current-page-ancestor').find('> .sub-menu').addClass("is-active");
    
    
    
  }
  
  // Conferences highlighting
  if ($('.conferences').is(".is-active")) {   
    $('.conferences').addClass(second_level);

  }
  if ($('.conferences').is(".fade")) {
    $('.conferences').removeClass(second_level);
  }

  $(".menu-item-has-children").on("click touch", function (e) {
    e.stopPropagation();

    if ($(this).is(".is-active")) {
      $(this).addClass("fade");
      $(this).removeClass("is-active");
      $(this).find('> .sub-menu').removeClass("is-active");
    } else {
      $(this).removeClass("fade");
      $(this).addClass("is-active");
      $(this).find('> .sub-menu').addClass("is-active");
    }
  });


  const menuTrigger = $('.menu-icon > img');
  const menu = $('.menu-container');

  $(menuTrigger).on("click touch", function (e) {
    $(menuTrigger).toggleClass("is-visible is-hidden");

    $(menu).toggleClass("is-active");
  });

  $('.page-template-people main').css("display", "block");

  // highlight corresponding pages for Conference taxonomies in menu
  if (first_level === "conferences" && taxonomy != "") { // check if page is a conference's taxonomy page
    $('.main-menu').find(".conferences").addClass("is-active");
    $('.main-menu').find(".conferences").addClass(second_level);
    $('.main-menu').find(".conferences").addClass("current_page_parent");
    
    $('.main-menu').find(".conferences").find('> .sub-menu').addClass("is-active");
    // $('.main-menu').find(".conferences").find('> .sub-menu .sub-menu').addClass("is-active");
    
    if (second_level !== "" && second_level !== undefined) {
      $('.main-menu').find(".conferences").find('> .sub-menu').find("." + second_level).addClass("is-active");  
    }
    if (third_level !== "" && third_level !== undefined) {
      $('.main-menu').find(".conferences").find('> .sub-menu').find("." + third_level).addClass("current_page_item");
    }
  }

  if (first_level == "output-media" || first_level == "conferences" || first_level == "moocs" || first_level == "news" ) {
    $('.main-menu .blog').removeClass("current_page_parent");
  }

  if (first_level == "moocs") {
    $('.main-menu').find("." + $.escapeSelector('moocs')).addClass("current_page_parent");
  }

  if (first_level == "output-media") {
    $('.main-menu').find("." + $.escapeSelector('output/media')).addClass("current_page_parent");
  }
  
  if (first_level == "audio-visual-resources") {
    $('.main-menu').find("." + $.escapeSelector('audio-visual')).addClass("current_page_parent");
  }
});
