document.addEventListener('DOMContentLoaded', function() {
    const mappings = {
        'gruen': {
            'fill': 'rgb(190,209,141)',
            'url': 'https://gcsmus.org/action-4-exchange/'
        },
        'gelb': {
            'fill': 'rgb(250,224,185)',
            'url': 'https://gcsmus.org/action-2-experience/'
        },
        'blau': {
            'fill': 'rgb(154,208,228)',
            'url': 'https://gcsmus.org/action-1-educate/'
        },
        'violett': {
            'fill': 'rgb(220,198,224)',
            'url': 'https://gcsmus.org/action-3-evaluate/'
        },
        'rot': {
            'fill': 'rgb(241,178,177)',
            'url': 'https://gcsmus.org/action-5-enhance/'
        },
        'titel': {
            'fill': '#8685a5',
            'url': 'https://www.youtube.com/watch?v=9QmGIqtAphE'
        }
    };

    for (let id in mappings) {
        let element = document.getElementById(id);
        if (element) {
            element.addEventListener('mouseover', function() {
                let path = element.querySelector('path');
                if (path) {
                    path.dataset.originalFill = path.style.fill;
                    path.style.fill = mappings[id].fill;
                }
                element.style.cursor = 'pointer';
            });

            element.addEventListener('mouseout', function() {
                let path = element.querySelector('path');
                if (path) {
                    path.style.fill = path.dataset.originalFill;
                }
                element.style.cursor = 'default';
            });

            element.addEventListener('click', function() {
                if (id === 'titel') {
                    window.open(mappings[id].url, '_blank');
                } else {
                    window.location.href = mappings[id].url;
                }
            });
        }
    }
});
