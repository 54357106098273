document.addEventListener('DOMContentLoaded', () => {
  let currentAudio = null;
  let currentAudioContainer = null;

  // Funktion zum Stoppen der aktuellen Wiedergabe und Entfernen der Klassen
  const stopCurrentAudio = () => {
    if (currentAudio) {
      currentAudio.pause();
      currentAudio.currentTime = 0;
      if (currentAudioContainer) {
        currentAudioContainer.classList.remove('playing');
        const playButton = currentAudioContainer.querySelector('#playButton');
        const playButtonWhilePause = currentAudioContainer.querySelector('#playButtonWhilePause');
        const pauseButton = currentAudioContainer.querySelector('#pauseButton');
        playButton.classList.add('show');
        playButtonWhilePause.classList.remove('show');
        pauseButton.classList.remove("show");
      }
      currentAudio = null;
      currentAudioContainer = null;
    }
  };

  const playButtons = document.querySelectorAll('.clip-title img.play-audio');

  playButtons.forEach(playButton => {
    playButton.addEventListener('click', (e) => {
      const audioClipContainer = playButton.closest('.audio-clip');
      const audioSrc = playButton.getAttribute('data-audio-source');
      const playButtonWhilePause = audioClipContainer.querySelector('#playButtonWhilePause');
      const pauseButton = audioClipContainer.querySelector('#pauseButton');

      // Prüfe, ob ein neues Audio-Objekt erstellt oder das aktuelle Audio pausiert/fortgesetzt werden soll
      if (!currentAudio || currentAudio.src !== audioSrc) {
        stopCurrentAudio(); // Stoppe die aktuelle Wiedergabe, falls vorhanden

        const audio = new Audio(audioSrc);
        audio.play();
        currentAudio = audio;
        currentAudioContainer = audioClipContainer;

        audioClipContainer.classList.add('playing');
        playButton.classList.remove("show");
        pauseButton.classList.add("show");

        audio.onended = () => {
          audioClipContainer.classList.remove('playing');
          playButton.classList.add("show");
          pauseButton.classList.remove("show");
        };
      }

      // Event-Listener für den Pause-Button
      pauseButton.addEventListener('click', () => {
        if (currentAudio && !currentAudio.paused) {
          currentAudio.pause();
          pauseButton.classList.remove("show");
          playButtonWhilePause.classList.add('show'); // Zeige den playButtonWhilePause
        }
      });

      // Event-Listener für den playButtonWhilePause
      playButtonWhilePause.addEventListener('click', () => {
        if (currentAudio && currentAudio.paused) {
          currentAudio.play();
          audioClipContainer.classList.add('playing');
          playButtonWhilePause.classList.remove('show');
          pauseButton.classList.add("show");
        }
      });
    });
  });

  // Stoppe die Wiedergabe, wenn ein anderer Track gestartet wird
  document.addEventListener('play', (e) => {
    if (currentAudio && e.target !== currentAudio) {
      stopCurrentAudio();
    }
  }, true);
});
