$(document).ready(function() {
  // the event needs to be run before slick is initialized
  $('.slider').on('init', function (event, slick, direction) {
    // check to see if there are one or less slides
    if (!($('.slider .slick-slide').length > 1)) {
      // remove bulletpoint nav
      $('.slick-dots').hide()
    }
  })

  $('.slider-for').each(function (key, item) {
    let sliderIdName = 'slider' + key

    this.id = sliderIdName

    let sliderId = '#' + sliderIdName

    $(sliderId).slick({
      dots: true,
      infinite: true,
      speed: 1200,
      pauseOnHover: false,
      mobileFirst: true,
      fade: true,
      cssEase: 'ease-in-out',
      useTransform: true,
      adaptiveHeight: true,
      autoplay: false,
      prevArrow:
        '<div class="slider-arrow prevArrow"></div>',
      nextArrow:
        '<div class="slider-arrow nextArrow"></div>',
    })
  })
})

// Reinit slick slider after changing breakpoint
let resizeTimer;
const sliderPresent = $(".slider-for")[0];

if (sliderPresent) {
  $(window).on('resize', function () {
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(function() {
      $('.slider-for')[0].slick.refresh();      
    }, 200);
  });
}

