$(function () {
  const transcriptToggle = $(".transcript #toggleTranscript");
  const transcript = $(".transcript .text-content");
  const transcriptNotice = $(".transcript .transcript-notice");
  const caret = $(".transcript i");
  
  transcriptToggle.on("click", function () {
    transcript.toggleClass("hidden is-active");
    transcriptNotice.toggleClass("hidden is-visible");
    caret.toggleClass("default rotated");
  })
});
