$(function() {
  const section = $("main");

  return $("#arrow-down").click(function() {
    return $("html, body").animate(
      {
        scrollTop: section.offset().top
      },
      {
        duration: 1200,
        easing: "easeInOutExpo"
      }
    );
  });
});
