$(function() {
  const box = $('.page-template-output .sp-pcp-post .sp-pcp-post-title')

  function eachHeight() {
    box.each(function() {
      let width = $(this).width()
      let title_height = $(this).children('a').height()

      $(this).css({ 'height': width + 'px' })
      $(this).children('a').css({ 'padding-top': 'calc(100% - 8px - ' + title_height + 'px' })
    })
  }

  eachHeight()


  let resize
  const outputPagePresent = $('.page-template-output')[0]

  if (outputPagePresent) {
    $(window).on('resize', function() {
      clearTimeout(resize)
      resize = setTimeout(function() {
        $(window).trigger('resize-window')
      }, 100)
    })

    $(window).on('resize-window', function() {
      eachHeight()
    })
  }
})
